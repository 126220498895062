// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strip-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFEAC1;
    border-radius: 8px 8px 0px 0px;
    flex-wrap: wrap;
    /* Blue background color */
    color: #fff;
    /* White text color */
    padding: 1rem;
    min-height: 50px;
    width: 100%;
        /* Set the width */
        margin: 0 auto;
        /* Center the strip-bar horizontally */
    /* Set the height */
}

.left-side {
    color: black;
   
    /* You can add additional styling for the left side if needed */
}

.right-side {
    flex-wrap: wrap;
    /* You can add additional styling for the right side if needed */
}

.right-side > select {
    padding: 5px;
}

/* .card-container {
            display: flex;
            gap: 20px;  
        }

        .card {
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            width: 200px;  
            max-width: 100%;
        }

        .card-header {
            background-color: #3498db;
            color: #fff;
            padding: 15px;
            text-align: center;
        }

        .card-body {
            padding: 20px;
        } */`, "",{"version":3,"sources":["webpack://./src/component/SocialSentimentView/SocialSentimentView.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,8BAA8B;IAC9B,eAAe;IACf,0BAA0B;IAC1B,WAAW;IACX,qBAAqB;IACrB,aAAa;IACb,gBAAgB;IAChB,WAAW;QACP,kBAAkB;QAClB,cAAc;QACd,sCAAsC;IAC1C,mBAAmB;AACvB;;AAEA;IACI,YAAY;;IAEZ,+DAA+D;AACnE;;AAEA;IACI,eAAe;IACf,gEAAgE;AACpE;;AAEA;IACI,YAAY;AAChB;;AAEA;;;;;;;;;;;;;;;;;;;;;;WAsBW","sourcesContent":[".strip-bar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #FFEAC1;\n    border-radius: 8px 8px 0px 0px;\n    flex-wrap: wrap;\n    /* Blue background color */\n    color: #fff;\n    /* White text color */\n    padding: 1rem;\n    min-height: 50px;\n    width: 100%;\n        /* Set the width */\n        margin: 0 auto;\n        /* Center the strip-bar horizontally */\n    /* Set the height */\n}\n\n.left-side {\n    color: black;\n   \n    /* You can add additional styling for the left side if needed */\n}\n\n.right-side {\n    flex-wrap: wrap;\n    /* You can add additional styling for the right side if needed */\n}\n\n.right-side > select {\n    padding: 5px;\n}\n\n/* .card-container {\n            display: flex;\n            gap: 20px;  \n        }\n\n        .card {\n            border-radius: 15px;\n            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n            overflow: hidden;\n            width: 200px;  \n            max-width: 100%;\n        }\n\n        .card-header {\n            background-color: #3498db;\n            color: #fff;\n            padding: 15px;\n            text-align: center;\n        }\n\n        .card-body {\n            padding: 20px;\n        } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
