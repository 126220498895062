import React, { useState,useContext, useRef } from "react";
import { ColorContext } from "../pages/Dashboard/DashboardPage";
import { useNavigate  } from "react-router-dom";
import registerationimg from '../components/assets/images/registration1.png';
import alcisimg from '../components/assets/images/Decisionow.svg';
import validation from "../config/validation"; 
import environment from "../config/environment";
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab, Grid, FormControl, InputLabel, Input, Select, MenuItem, Button, Checkbox, FormHelperText, Avatar, Container, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
const axios = require("axios");



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } 

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ProfilePage = () => {
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState("");
  const [jobTitle, setJobTitle] = React.useState('');
  const [departmentValue, setDepartmentValue] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [updatePassword, setUpdatePassword] = useState('');
  
  const hiddenFileInput = useRef(null);
  const hiddenCompanyLogo = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoPreview, setCompanyLogoPreview] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);
  const { avatar, setAvatar } = useContext(ColorContext);

  const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  
    const handleChangeName = (event) => {
        changeName(event.target.value);
      }; 

    const handleChangeEmail = (event) => {
        changeEmail(event.target.value);
  }; 

  const handleJobTitle = (event) => {
    setJobTitle(event.target.value);
  }
  
  const handleDepartmentValue = (event) => {
    setDepartmentValue(event.target.value);
  }

  const handleAboutMe = (event) => {
    setAboutMe(event.target.value);
  }

  const handleUpdatePassword = (event) => {
    setUpdatePassword(event.target.value);
  }

  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  }
   const handleImageUpload = (event) => {
      const file = event.target.files[0];
      console.log('THE FILE AVATAR WHICH SHOULD BE SELECTED', file)
     if (file) {
      setAvatarImage(file);
      setSelectedImage(URL.createObjectURL(file));
       }
  };

  const handleCompanyLogo = (event) => {
    const file = event.target.files[0];
     console.log('THE COMPANY LOGO', file)
    if (file) {
      setCompanyLogo(file);
      setCompanyLogoPreview(URL.createObjectURL(file));
    }
  }
  
   const handleImageClick = event => {
    hiddenFileInput.current.click();
  };

   const handleLogoClick = event => {
    hiddenCompanyLogo.current.click();
  };
     const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


    const handleProfilePageRegistration = (event) =>{
      event.preventDefault();
      var formData = new FormData();
      formData.append("name", name.trim());
      formData.append("email", email.trim());
      formData.append("jobTitle", jobTitle.trim());
      formData.append("department", departmentValue.trim());
      formData.append("phone", phoneNumber.trim())
      formData.append("aboutMe", aboutMe.trim());
      formData.append("avatar", avatarImage);
      formData.append("userId", localStorage.getItem('USERID'));
      axios
        .post(environment.web.backendUri + "/user/updateUser", formData)
        .then((response) => {
          if (response.status === 200) {
          setAvatar(response.data.avatarImage);
          setOpen(true);
          setError(response.data.message);
          }
        })
        .catch((error) => {
          setOpen(true);
          setError(error.response.data.message);
        });
  };
  
  const handleAccountPageRegistration = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("password", updatePassword.trim());
    formData.append("avatar", companyLogo);
    formData.append("userId", localStorage.getItem('USERID'));
     axios
        .post(environment.web.backendUri + "/user/updateUserProfile", formData)
        .then((response) => {
          if (response.status === 200) {
        //  setAvatar(response.data.avatarImage); set for company logo yet to make
          setOpen(true);
          setError(response.data.message);
          }
        })
        .catch((error) => {
          setOpen(true);
          setError(error.response.data.message);
        });
  }

    return (
      <>
         <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal:'center' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
        <Alert onClose={handleClose} sx={{ width: '100%' }}>
         {error}
        </Alert>
          </Snackbar>
    
        <Container maxWidth="md" sx={{ backgroundColor: "#fff" }}><h1>My Settings</h1>
           <Box sx={{ width: '100%' }}>
           <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs  TabIndicatorProps={{
    style: {
      height:"4px",
      backgroundColor: "#F6655A"
    }
  }} value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Profile" {...a11yProps(0)} />
          {/* <Tab label="Notifications" {...a11yProps(1)} /> */}
          <Tab label="Account" {...a11yProps(1)} />
        </Tabs>
            </Box>
          </Box>
        <TabPanel value={value} index={0}>
        <form onSubmit={handleProfilePageRegistration} noValidate autoComplete="off">
        <Grid container sx={{ flexGrow: 1 }}>
       
          <Grid xs={6} display="flex" flexDirection="column">
               
                        <FormControl  variant="standard">
                          <InputLabel htmlFor="component-simple">Name</InputLabel>
                          <Input onChange={handleChangeName} 
                           type="text"
                            value={name}
                            id="component-simple" variant="outlined" color="primary" />
                        </FormControl>
                        <Box mt={3}>
                        <FormControl variant="standard" sx={{ width:"100%"}}>
                          <InputLabel>E-mail</InputLabel>
                          <Input onChange={handleChangeEmail} />
                        </FormControl>
                        </Box>

                     <Box mt={3}>
                        <FormControl variant="standard" sx={{ width:"100%"}}>
                            <InputLabel htmlFor="component-helper">Job Title</InputLabel>
                            <Input  value={jobTitle} onChange={handleJobTitle}  id="component-simple-job-title" variant="outlined" color="primary" />
                        </FormControl>
                        </Box>
                        <Box mt={3}>
                        <FormControl variant="standard" sx={{ width:"100%"}}>
                            <InputLabel  htmlFor="component-helper">Department or team</InputLabel>
                            <Input value={departmentValue}
                              onChange={handleDepartmentValue}
                              id="component-simple-department" variant="outlined" color="primary" />
                        </FormControl>
                        </Box>
                        <Box mt={3}>
                           <FormControl variant="standard" sx={{ width:"100%"}}>
                          <InputLabel>Phone number</InputLabel>
                            <Input onChange={handlePhoneNumber} type="text" value={phoneNumber}
                            id="component-simple-phone-number" variant="outlined" color="primary"/>
                        </FormControl>
                        </Box>
                        <Box mt={3}>
                        <FormControl variant="standard" sx={{ width:"100%"}}>
                          <InputLabel>About me</InputLabel>
                            <Input onChange={handleAboutMe} type="text" value={aboutMe}
                               id="component-simple-about-me" variant="outlined" color="primary"
                            />
                        </FormControl>
                        </Box>
                        <Box mt={3}>
                    <Button type="submit" variant="contained" sx={{ '&:hover': { bgcolor: "#D87474" }, borderRadius: "4px", backgroundColor: "#D87474", padding: "10px 20px", color: "#fff"}}>
                      Submit Details
                    </Button>
             </Box>
             
          </Grid>
          <Grid xs={6} display="flex"><Grid width="100%" sx={{ alignItems :"center", display: "flex", justifyContent: "flex-start", flexDirection: "column", gap: "2"}}>
                    <Box mb={1}><Avatar src={selectedImage ? selectedImage : '/broken-image.jpg'} sx={{ width: 143, height: 143 }}/></Box>
                      <Box mb={1}><Button variant="outlined" onClick={handleImageClick} >Change</Button></Box>
                    <FormHelperText>PNG, JPG (max. 1 MB)</FormHelperText>
                      <input type="file"
                      ref={hiddenFileInput}
                      onChange={handleImageUpload}
                      style={{ display: 'none' }} >
                      </input>
                      </Grid></Grid>     
            </Grid>
            </form>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
      <Box width="100%">
                <Typography pb={2} variant="h5">Do not disturb</Typography>
                <Button variant="outlined" startIcon={<NotificationsOffOutlinedIcon />}>
                Pause notifications
</Button>

<Typography mt={3} pb={2} variant="h5">Email notifications</Typography>
<Typography variant="body1">Send me email notifications for</Typography>
<Box sx={{ width: "100%", display: "flex",flexDirection: "row", justifyContent: "flex-start", alignItems:"baseline" }} pt={1} mb={4}>
<Box><Checkbox defaultChecked sx={{ marginTop:"-1px"}} /></Box><Box>
      <Typography variant="Body2" fontWeight={400} textAlign="left" color="#292A31">
      Activity Updates
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" pt={1} color="#787885">
        Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. 
          </Typography>
        </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex",flexDirection: "row", justifyContent: "flex-start", alignItems:"baseline" }} pt={1} mb={4}>
<Box><Checkbox sx={{ marginTop:"-1px"}} /></Box><Box>
      <Typography variant="Body2" fontWeight={400} textAlign="left" color="#292A31">
      Daily Summary
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" pt={1} color="#787885">
        Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. 
          </Typography>
        </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex",flexDirection: "row", justifyContent: "flex-start", alignItems:"baseline" }} pt={1} mb={4}>
<Box><Checkbox sx={{ marginTop:"-1px"}} /></Box><Box>
      <Typography variant="Body2" fontWeight={400} textAlign="left" color="#292A31">
      Cohort analysis
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" pt={1} color="#787885">
        Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. 
          </Typography>
        </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex",flexDirection: "row", justifyContent: "flex-start", alignItems:"baseline" }} pt={1} mb={4}>
<Box><Checkbox sx={{ marginTop:"-1px"}} /></Box><Box>
      <Typography variant="Body2" fontWeight={400} textAlign="left" color="#292A31">
      Dashboard updates
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" pt={1} color="#787885">
        Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. Prel nekär som deligt. Böda pafan, mäjägen. Sms-livräddare ROPO SME. 
          </Typography>
        </Box>
        </Box>
        
                </Box>
          </TabPanel> */}
      <TabPanel value={value} index={1}>
      {/* <Box width="100%">
                <Typography pb={2} variant="h5">Company Thumbnail</Typography>
                </Box>
                <Box mb={2}><Avatar src="/broken-image.jpg" sx={{ width: 48, height: 48 }}/></Box>
                <Button variant="outlined">Change</Button>
            <FormHelperText>PNG, JPG (max. 1 MB)</FormHelperText> */}
            

            <form onSubmit={handleAccountPageRegistration} noValidate autoComplete="off">

                   <Grid width="100%" sx={{ alignItems :"left", display: "flex", justifyContent: "flex-start", flexDirection: "column", gap: "2"}}>
                    <Box mb={1}><Avatar src={companyLogoPreview ? companyLogoPreview : '/broken-image.jpg'} sx={{ width: 143, height: 143 }}/></Box>
                      <Box mb={1}><Button variant="outlined" onClick={handleLogoClick} >Company Thumbnail</Button></Box>
                    <FormHelperText>PNG, JPG (max. 1 MB)</FormHelperText>
                      <input type="file"
                      ref={hiddenCompanyLogo}
                      onChange={handleCompanyLogo}
                      style={{ display: 'none' }} >
                      </input>
                      </Grid>

                    <Box display="flex" gap={2} mb={3} className="formContainer">
                      <Grid mr={3} xs={6}>
                        <FormControl  variant="standard">
                          <InputLabel htmlFor="component-simple">Change Password</InputLabel>
                          <Input onChange={handleUpdatePassword} 
                            type="password"
                            value={updatePassword}
                            id="component-simple-password" variant="outlined" color="primary" />
                        </FormControl>
                      </Grid>
              </Box>
              <Button type="submit" variant="contained" sx={{ '&:hover': { bgcolor: "#D87474" }, borderRadius: "4px", backgroundColor: "#D87474", padding: "10px 20px", color: "#fff"}}>
                      Submit Details
                    </Button>
            </form>
                {/* <Typography variant="h5" mt={3} mb={2}>Password</Typography>
                <Button variant="outlined">Change password</Button> */}
                {/* <Typography variant="h5" mt={3}>Security</Typography>
<Box sx={{ width: "100%", display: "flex",flexDirection: "row", justifyContent: "flex-start", alignItems:"baseline" }} pt={1} mb={4}>
<Box><Checkbox defaultChecked sx={{ marginTop:"-1px"}} /></Box><Box>
      <Typography variant="Body2" fontWeight={400} textAlign="left" color="#292A31">
      Log out of all sessions except this current browser
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" pt={1} color="#787885">
        You will be logged out of all associated devices except for the one you are currently using
          </Typography>
        </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex",flexDirection: "row", justifyContent: "flex-start", alignItems:"baseline" }} pt={1} mb={4}>
<Box><Checkbox sx={{ marginTop:"-1px"}} /></Box><Box>
      <Typography variant="Body2" fontWeight={400} textAlign="left" color="#292A31">
      Deactivate account
          </Typography>
        <Typography variant="body1" fontWeight={300} textAlign="left" pt={1} color="#787885">
                  Close your account.
                 

                  <button className="button" onClick={() => setAvatar("https://avatars.githubusercontent.com/u/23469188?v=4")}>CLICK</button>
          </Typography>
        </Box>
        </Box> */}
          </TabPanel>
        </Container>
      </>
    );
};
export { ProfilePage }; 