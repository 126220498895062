import { LayOutView } from "../LayOutView/LayOutView";
import { useSelector } from "react-redux";
const Sales = () => {
  const CategoryName = "Sales";
  const userDashboardObject = useSelector((state) => state.storeData.userDashboardInfo);
  const getCategoryDashboardUrl = (categoryName) => {
    const category = userDashboardObject?.data.find((item) => item.category_name === categoryName);
    return category ? category.dashboard_url : null;
  };
  const subscriptionUrl = getCategoryDashboardUrl(CategoryName);
  return (
    <>
      <LayOutView>
        <div class="responsive-iframe iframe">
          <iframe
            title="Report Section"
            src={subscriptionUrl?subscriptionUrl:null}
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        </div>
      </LayOutView>
    </>
  );
};
export { Sales };
