import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "users",
  initialState: {
    years: null,
    userInfo: null,
    categoryInfo: null,
    userDashboardInfo: null
  },
  reducers: {
    addYears(state, action) {
      state.years = action.payload;
    },
    fetchLoggeInUserInfo(state, action) {
      const userData = action?.payload?.data[0];
      if (userData) {
        const { email, name, user_id } = userData;
        state.userInfo = { email, name, user_id };
      } else {
        console.error("User data is undefined or empty.");
      }
    },
    fetchCategoryInfo(state, action) {
      state.categoryInfo = action?.payload[0];
    },
    fetchUserDashboardInfo(state, action) {
      state.userDashboardInfo = action?.payload;
    },
  },
});
export default userSlice.reducer;
export const { addYears, fetchLoggeInUserInfo, fetchCategoryInfo, fetchUserDashboardInfo } = userSlice.actions;
