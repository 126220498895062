import { React, useEffect } from "react";
import { Header } from "../Header/Header";
import { SideBar } from "../SideBar/SideBar";
import { useDispatch } from "react-redux";
import { useFetchUserLogedInData } from "../../hooks/chartHook";
import { fetchLoggeInUserInfo, fetchCategoryInfo, fetchUserDashboardInfo } from "../../Store/slices/UserSlice";
const LayOutView = ({ children }) => {
  const dispatch = useDispatch();
  const {
    data: userInfo,
    isLoading,
    isFetching,
    error,
  } = useFetchUserLogedInData({
    userId: localStorage.getItem("USERID"),
    enableRefetch: false,
  });

  useEffect(() => {
    const categories = [];
    dispatch(fetchLoggeInUserInfo(userInfo));
    const categoryNames = userInfo?.data?.map(item => item.category_name);
    categories.push(categoryNames);
    dispatch(fetchCategoryInfo(categories));
    dispatch(fetchUserDashboardInfo(userInfo));
  }, [dispatch, userInfo]);
  return (
    <>
      <Header></Header>
      <SideBar></SideBar>
      <main>{children}</main>
    </>
  );
};
export { LayOutView };
