import { useState } from "react";
import * as React from 'react';
import { Box, Grid, Typography, FormHelperText, Button, FormControl, InputLabel, Input, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useNavigate  } from "react-router-dom";
import { Container } from "@mui/system";
import registerationimg from '../components/assets/images/registration1.png';
import alcisimg from '../components/assets/images/Decisionow.svg';
import validation from "../config/validation"; 
import environment from "../config/environment";
const axios = require("axios");


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegistrationPage = () => {
  const navigate = useNavigate();
   const [error, setError] = useState();
  const [open, setOpen] = useState(false);
    const [name, changeName] = useState("");
    const [email, changeEmail] = useState("");
    const [companyname, changeCompanyName] = useState("");
    const [companytype, changeCompanyType] = useState("");
    const [password, changePassword] = useState("");
    const [webAddress, changeWebAddress] = useState("");
    const [playStore, changePlayStore] = useState("");
    const [appStore, changeAppStore] = useState("");
    const [inputError, changeInputError] = useState({
      name: { invalid: false, message: "" },
      email: { invalid: false, message: "" },
      companyname: { invalid: false, massage:""},
      companytype: { invalid: false, massage:""},
      password: { invalid: false, message: "" },
    });
  const SIGNUPElEMENT = {
    'NAME': 'Name',
    'EMAIL': 'Email',
    'COMPANYNAME': 'Company Name',
    'COMPANYTYPE': 'Company Type',
    'WEBADDRESS': 'Web Address',
    'PLAYSTORELINK': 'Play Store Link',
    'APPSTORELINK': 'App Store Link',
    'PASSWORD': 'Password'
  }

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  
  const handleChangeName = (event) => {
      const {value} = event.target;
      const validNamePattern = /^[A-Za-z]+$/;
      
    if (value === '' || validNamePattern.test(value)) {
      changeName(event.target.value);
        var error = { ...inputError };
        error.name = { invalid: false, message: "" };
        if (event.target.value.trim() !== "") {
          changeInputError(error);
        }
    }
      }; 

      const handleChangeCompanyName = (event) => {
        changeCompanyName(event.target.value);
        var error = { ...inputError };
        error.companyname = { invalid: false, message: "" };
        if (event.target.value.trim() !== "") {
          changeInputError(error);
        }
      };
      
      const handleChangeCompanyType = (event) => {
        changeCompanyType(event.target.value);
        var error = { ...inputError };
        error.companytype = { invalid: false, message: "" };
        if (event.target.value.trim() !== "") {
          changeInputError(error);
        }
  };
   
     const handleChangeWebAddress = (event) => {
       changeWebAddress(event.target.value);
    }
  
     const handleChangechagePlayStore = (event) => {
        changePlayStore(event.target.value);
     }

      const handleChangeAppStore = (event) => {
       changeAppStore(event.target.value);
     }

  const handleChangeEmail = (event) => {
        const regexp = /^\S*$/;
    if (!regexp.test(event.target.value)) {
      return;
    }
        changeEmail(event.target.value);
        var error = { ...inputError };
        error.email = { invalid: false, message: "" };
        if (event.target.value.trim() !== "") {
          changeInputError(error);
        }
      }; 

  const handleChangePassword = (event) => {
        changePassword(event.target.value);
        var error = { ...inputError };
        error.password = { invalid: false, message: "" };
        if (event.target.value !== "") {
          changeInputError(error);
        }
      };
 
    const handleRegistration = (event) =>{
      event.preventDefault();
        var validateResponse = validation({
          email: email,
          name: name,
          companyname: companyname, 
          companytype: companytype,
          password: password,
        });
    
        if (validateResponse.invalid) {
          var errors = validateResponse.errors;
          changeInputError({
            name: { invalid: errors.name.invalid, message: errors.name.message },
            email: { invalid: errors.email.invalid, message: errors.email.message },
            companyname: { invalid: errors.companyname.invalid, message: errors.companyname.message },
            companytype:{ invalid: errors.companytype.invalid, message: errors.companytype.message },
            password: {
              invalid: errors.password.invalid,
              message: errors.password.message,
            },
          });
      }
      
 if (
   name.trim() !== "" &&
   email.trim() !== "" &&
   password.trim() !== "" &&
   companyname.trim() !== "" && 
   companytype.trim() !== "" &&
      !validateResponse.invalid
    ) {
   let data = { name: name.trim(), email: email.trim(), password: password, company_name: companyname.trim(), company_type: companytype.trim(), web_address: webAddress.trim(), play_store: playStore.trim(), app_store: appStore.trim()};
      axios
        .post(environment.web.backendUri + "/user/register", data)
        .then((response) => {
          if (response.status === 200) {
             localStorage.setItem('USERID', response?.data?.data[0]?.user_id)
            axios.post(environment.web.flaskbackendUri + '/register/' + email.trim()).then((response) => {
              if (response.status === 201) {
                navigate("/splashscreen"); // when not dashbaord ready then navigate to splashscreen
              } else {
                navigate("/login"); // navigate to login when resgistered
              }
            })
        .catch((error) => {
        });
          }
          changeName("");
          changeEmail("");
          changePassword("");
        })
        .catch((error) => {
          setOpen(true);
          setError(error.response.data.message);
          console.log('REGISTER ERROR---->', error.response.data.message)
        });
    }
    };

    return (
      <>
         <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal:'center' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
         {error}
        </Alert>
          </Snackbar>
    
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid py={13} textAlign="center" xs={6} sx={{ background: "#D87474" }}>
            <Box width="70%" sx={{ margin: "0 auto" }}>
              <img src={registerationimg} alt={registerationimg} height="100%" />
              <Typography color="#fff" pt={4} variant="h3">Track Advocacy through online reviews</Typography>
              <Typography color="#fff" pt={4} component="div" variant="button">NPS Score and Churn rate can help product teams identify loyal users. </Typography>
            </Box>
          </Grid>
          <Grid xs={6} display="flex" mt={12}>
            <Container maxWidth="lg">
              <Box sx={{ margin: "0 auto" }} width="70%"><img src={alcisimg} alt={alcisimg} height="100%" />
                <Typography pb={2} variant="h3">Registration Form</Typography>
                <Typography pb={2} component="div" variant="button">Know exactly when is your app is reviewed and act on it.</Typography>
                <Box component="div"
                  sx={{
                    '& > :not(style)': { mb: 3 },
                  }}>

                  <form onSubmit={handleRegistration} noValidate autoComplete="off">
                    <Box display="flex" gap={2} mb={3} className="formContainer">
                      <Grid mr={3} xs={6}>
                        <FormControl error={inputError.name.invalid} variant="standard">
                          <InputLabel htmlFor="component-simple">{SIGNUPElEMENT.NAME} *</InputLabel>
                          <Input onChange={handleChangeName} 
                           type="text"
                            value={name}
                            id="component-simple" variant="outlined" color="primary" />
                          <FormHelperText id="component-error-text" className={inputError.name.invalid ? "" : "d-none"}>
                            {inputError.name.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl error={inputError.email.invalid} variant="standard">
                          <InputLabel htmlFor="component-helper">{SIGNUPElEMENT.EMAIL} *</InputLabel>
                          <Input onChange={handleChangeEmail} id="component-helper" value={email} />
                          <FormHelperText id="component-error-text" className={inputError.email.invalid ? "" : "d-none"}>
                            {inputError.email.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Box>
                    <Box display="flex" gap={2} mb={3}>
                      <Grid mr={3} xs={6}>
                        <FormControl error={inputError.companyname.invalid} variant="standard">
                          <InputLabel>{SIGNUPElEMENT.COMPANYNAME} *</InputLabel>
                          <Input onChange={handleChangeCompanyName} value={companyname} />
                          <FormHelperText id="component-error-text" className={inputError.companyname.invalid ? "" : "d-none"}>
                            {inputError.companyname.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl error={inputError.companytype.invalid} variant="standard">
                          <InputLabel>{SIGNUPElEMENT.COMPANYTYPE} *</InputLabel>
                          <Input onChange={handleChangeCompanyType} value={companytype} />
                          <FormHelperText id="component-error-text" className={inputError.companytype.invalid ? "" : "d-none"}>
                            {inputError.companytype.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Box>
                    <Box display="flex" gap={2} mb={3}>
                      <Grid mr={3} xs={6}>
                        <FormControl variant="standard">
                          <InputLabel>{SIGNUPElEMENT.WEBADDRESS}</InputLabel>
                          <Input onChange={handleChangeWebAddress} value={webAddress} />
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl variant="standard">
                          <InputLabel>{SIGNUPElEMENT.PLAYSTORELINK}</InputLabel>
                          <Input onChange={handleChangechagePlayStore} value={playStore} />
                        </FormControl>
                      </Grid>
                    </Box>
                    <Box display="flex" gap={2} mb={3}>
                      <Grid mr={3} xs={6}>
                        <FormControl variant="standard">
                          <InputLabel>{SIGNUPElEMENT.APPSTORELINK}</InputLabel>
                          <Input onChange={handleChangeAppStore} value={appStore} />
                        </FormControl></Grid>
                      <Grid xs={6}>
                        <FormControl error={inputError.password.invalid} variant="standard">
                          <InputLabel>{SIGNUPElEMENT.PASSWORD} *</InputLabel>
                          <Input onChange={handleChangePassword} type="password" value={password} />
                          <FormHelperText
                            id="component-error-text"
                            className={inputError.password.invalid ? "" : "d-none"}
                          >
                            {inputError.password.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Box>
                    <Button type="submit" variant="contained" sx={{ '&:hover': { bgcolor: "#D87474" }, borderRadius: "4px", backgroundColor: "#D87474", padding: "10px 20px"}}>
                      Submit Details
                    </Button>
                  </form>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </>
    );
};
export { RegistrationPage }; 