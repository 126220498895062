import { createTheme } from "@mui/material/styles";
//Todos
// For fonts installation if required: https://blog.logrocket.com/add-custom-fonts-mui/
// Need to add color palletes for Color codes in the theme

//Typography Responsive Content 👇
//Responsive reference: https://v4.mui.com/customization/typography/#responsive-font-sizes
// Breakpoint reference: https://v4.mui.com/customization/breakpoints/#api
// (Please dont delete keep it for calculation of fonts.) 👇
// BaseFont => 16px
// 46px  => 2.875rem     => H1
// 40px  => 2.5rem     => H2
// 38px  => 2.375rem   => H3
// 36px  =>  2.25rem   
// 34px  => 2.125rem
// 32px  => 2rem       => H4
// 30px  => 1.875rem
// 28px  => 1.75rem    => H5
// 26px  => 1.625rem
// 24px  => 1.5rem     => H6
// 22px  => 1.375rem   => Subtitle1
// 20px  => 1.25rem    => Subtitle2 + Body1 [Subtitles font size incorrect need to verify across the app]
// 18px  => 1.125rem
// 16px  => 1rem (base) =>Body2
// 14px  => 0.875rem
// 12px  => 0.75rem => DefaultBody
// 10px  => 0.625rem

const aclisTheme = createTheme({
  h1: {
    fontSize: "2.875rem",
  },
  h2: {
    fontSize: "2.5rem",
  },
  h3: {
    fontSize: "2.375rem",
  },
  h4: {
    fontSize: "2rem",
  },
  h5: {
    fontSize: "1.75rem",
  },
  h6: {
    fontSize: "1.5rem",
  },
  subtitle1: {
    fontSize: "1.375rem",
  },
  subtitle2: {
    fontSize: "1.25rem",
  },
  body1: {
    fontSize: "1.25rem",
  },
  body2: {
    fontSize: "1rem",
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  components: {
    // Name of the component
    MuiList: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
          fontWeight: "500",
          //background: "red",
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "1rem",
          fontWeight: "500",
        },
        primary: {
          fontSize: "1rem",
          fontWeight: "500",
        },
        secondary: {
          fontSize: ".75rem",
          fontWeight: "400",
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "0.875rem",
          fontWeight: "500",
          color: "#3A3A44",
        },
        primary: {
          fontSize: "0.875rem",
          fontWeight: "500",
          color: "#3A3A44",
        },
        secondary: {
          fontSize: "0.75rem",
          fontWeight: "400",
          color: "#9696A0",
        },
      },
    },

    MuiButton:{
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.875rem",
          fontWeight: "500",
          background: "rgb(160, 33, 32)",
          border: "1px solid rgb(160, 33, 32)",
          color:"#fff",
          padding:"11px 24px",
"&:hover":{
  background:"#77d0ea",
  border: "1px solid rgb(160, 33, 32)",
},
        },
        secondary: {
          fontSize: "0.875rem",
          fontWeight: "500",
          color: "#3A3A44",
          background:"red"
        },
      },
    },

    MuiTab:{
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "1rem",
          color: "#787885",
          fontWeight: "500",
          borderRadius:"4px 4px 0px 0px",
          "&.Mui-selected": {
            background: "#77d0ea",
            color:"#3A3A44",
          },
      },
    },
    },
    MuiDialog:{
      styleOverrides: { 
        root: {  
            "& .MuiDialog-paperWidthMd":{
              maxWidth:"801px",
            },
            "& h2":{
              fontSize:"1rem",
            },
            "& .MuiDialogContent-root":{
              border:"0px"
            }  
      },
    },
    },
    MuiAvatar: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // width: "1.75rem",
          // height: "1.75rem",
          fontSize: "0.75rem",
          fontWeight: "400",
          background: "#77d0ea",
          color:"#F6655A"
        },
        textColorPrimary:{
          color:"red",
        }
      },
    },
  },
});


aclisTheme.typography.h1 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1.75rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
};
aclisTheme.typography.h2 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "2.375rem",
    lineHeight: "3rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
};
aclisTheme.typography.h3 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "2.25rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1.375rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1.125rem",
  },
};
aclisTheme.typography.h4 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};
aclisTheme.typography.h5 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "1.75rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1.75rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};
aclisTheme.typography.h6 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};
aclisTheme.typography.subtitle1 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "1.375rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};
aclisTheme.typography.subtitle2 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "1.25rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
};
aclisTheme.typography.body1 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "1.25rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
};
aclisTheme.typography.body2 = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
};
aclisTheme.typography.button = {
  [aclisTheme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [aclisTheme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
  },
  [aclisTheme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
};

export { aclisTheme };
