import React, { useState } from "react";
import { Box, Grid, Typography, FormControl, FormHelperText, InputLabel, Input, Button, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { Container } from "@mui/system";
import { useNavigate  } from "react-router-dom";
import validation from "../config/validation"; 
import loginimg from '../components/assets/images/login1.png';
import alcisimg from '../components/assets/images/Decisionow.svg';
import environment from "../config/environment";
const axios = require("axios");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
    const [email, changeEmail] = useState("");
    const [login_password, changePassword] = useState("");
    const [inputError, changeInputError] = useState({
      email: { invalid: false, message: "" },
      login_password: { invalid: false, message: "" },
    });

     
  const handleChangeEmail = (event) => {
    const regexp = /^\S*$/;
    if (!regexp.test(event.target.value)) {
      return;
    }
        changeEmail(event.target.value);
        var error = { ...inputError };
        error.email = { invalid: false, message: "" };
        if (event.target.value.trim() !== "") {
          changeInputError(error);
        }
      }; 

  const handleChangePassword = (event) => {
        changePassword(event.target.value);
        var error = { ...inputError };
        error.password = { invalid: false, message: "" };
        if (event.target.value !== "") {
          changeInputError(error);
        }
      };

   const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

    const handleLogin = (event) => {
        event.preventDefault();
        var validateResponse = validation({
          email: email,
          login_password: login_password,
        });
        if (validateResponse.invalid) {
          var errors = validateResponse.errors;
          changeInputError({
            email: { invalid: errors.email.invalid, message: errors.email.message },
            login_password: {
              invalid: errors.login_password.invalid,
              message: errors.login_password.message,
            },
          });
        }
        if (
        email.trim() !== "" &&
        login_password.trim() !== "" &&
        !validateResponse.invalid
     ) {
      let data = {email: email.trim(), password: login_password};
      axios
        .post(environment.web.backendUri + "/user/login", data)
        .then((response) => {
          if (response.status === 200) {
             localStorage.setItem('token', response?.data?.token);
            localStorage.setItem('USERID', response?.data?.data[0]?.user_id);
            localStorage.setItem("isLogged", JSON.stringify(true));
            localStorage.setItem('COMPANYNAME', response?.data?.data[0]?.company_name);
           navigate("/dashboard");
          }
          changeEmail("");
          changePassword("");
        })
        .catch((error) => {
          setOpen(true);
          setError(error.response.data.message);
        });
    }
    };
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal:'center' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
         {error}
        </Alert>
          </Snackbar>
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid py={13} textAlign="center" Item xs={6} sx={{ background: "#D87474" }}>
                    <Box width="70%" sx={{margin:"0 auto"}}> 
                    <img src={loginimg} alt={loginimg} height="100%" />
                    <Typography color="#fff" pt={4} variant="h3">Track Advocacy through online reviews</Typography>
                    <Typography color="#fff" pt={4} component="div" variant="button">NPS Score and Churn rate can help product teams identify loyal users. </Typography>
                    </Box>
                </Grid>
                <Grid Item xs={6} display="flex" mt={12}>
                    <Container maxWidth="lg">
                        <Box sx={{margin:"0 auto"}} width="70%"><img src={alcisimg} alt={alcisimg} height="100%" />
                            <Typography pb={2} variant="h2">Welcome Back!</Typography>
                            <Typography pb={2} component="div" color="#000" variant="button" >Please log in to your account to view complete dashboard.</Typography>
                        
                            <Box mt={3} component="div"
      sx={{
        '& > :not(style)': { mb: 3 },
      }}
      >    

      <form
                onSubmit={handleLogin}
                autocomplete="off"
                style={{ display: "flex", flexDirection: "column" }}
    >                          
      <Grid xs={12}>
            <FormControl error={inputError.email.invalid} sx={{width:"100%"}} variant="standard">
        <InputLabel htmlFor="component-simple">Email *</InputLabel>
                        <Input onChange={handleChangeEmail} id="component-simple"
                          value={email}
                        />
                                        <FormHelperText id="component-error-text" className={inputError.email.invalid ? "" : "d-none"}>
                            {inputError.email.message}
                          </FormHelperText>
               </FormControl>
               </Grid> 
               <Grid mt={2} xs={12}>
            <FormControl sx={{width:"100%"}} error={inputError.login_password.invalid}  variant="standard">           
        <InputLabel htmlFor="component-helper">Password *</InputLabel>
                        <Input onChange={handleChangePassword} id="component-helper" type="password" value={login_password} />
                                         <FormHelperText
                            id="component-error-text"
                            className={inputError.login_password.invalid ? "" : "d-none"}
                          >
                            {inputError.login_password.message}
                          </FormHelperText>
                                    </FormControl>
                                    </Grid>
                                 <Box mt={3}><Button type="submit" variant="contained" py="2" sx={{ '&:hover': { bgcolor: "#A45959" }, borderRadius: "4px", backgroundColor: "#D87474", padding: "10px 20px"}}>
                      Login
                    </Button></Box>
                        </form>
                        </Box>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
};
export { LoginPage };