import React from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Home from "../assets/images/home-icon.svg";
import Subscription from "../assets/images/subscription.svg";
import Marketing from "../assets/images/marketing.svg";
import Sales from "../assets/images/sales.svg";
import Finance from "../assets/images/finance.svg";
import HRAnalytics from "../assets/images/hr-analytics.svg";
import HRWorkforce from "../assets/images/hr-workforce.svg";
import CustomerService from "../assets/images/customer-service.svg";
import EmailCampaign from "../assets/images/email-campaign.svg";
import DashBoardLogo from "../assets/images/dashboard-icon.svg";
import aclisLogo from "../assets/images/logo-aclis.svg";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./SideBar.css";

const SideBar = () => {
    const location = useLocation();
    const reduxStateObject = useSelector((state) => state.storeData.categoryInfo);
    
   
    const getUrlForCategory = (category) => {
      if (category === 'Subscription') {
        return 'subscription';
      } else if(category === 'Marketing') {
        return 'marketing';
      } else if(category === 'Sales') {
        return 'sales';
      } else if(category === 'Finance') {
        return 'finance';
      } else if(category === 'HR analytics') {
        return 'hranalytics';
      } else if(category === 'HR workforce') {
        return 'hrworkforce';
      } else if(category === 'Customer service') {
        return 'customerservice';
      } else if(category === 'Email campaign') {
        return 'emailcampaign';
      }
    };
    const renderedList = reduxStateObject?.map((category, index) => {
      const url = getUrlForCategory(category);
      return (
        <li key={index}>
          { category === 'Subscription' && (<SVG src={Subscription} alt="Subscription" />)}
          { category === 'Marketing' && (<SVG src={Marketing} alt="Marketing" />)}
          { category === 'Sales' && (<SVG src={Sales} alt="Sales" />)}
          { category === 'Finance' && (<SVG src={Finance} alt="Fiannce" />)}
          { category === 'HR analytics' && (<SVG src={HRAnalytics} alt="HR analytics" />)}
          { category === 'HR workforce' && (<SVG src={HRWorkforce} alt="HR workforce" />)}
          { category === 'Customer service' && (<SVG src={CustomerService} alt="Customer Service" />)}
          { category === 'Email campaign' && (<SVG src={EmailCampaign} alt="Email Campaign" />)}
          <NavLink to={`/${url}`} className="nav-link" end>
          <span>{category}</span>
          </NavLink>
        </li>
      );
    });
   
 
    return (
      <Box
        sx={{
          zIndex: "1200",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          "li a": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0.5rem 0",
            gap: "0.25rem",
            textDecoration: "none",
            fontSize: "0.625rem",
            color: "#292A31",
            borderRadius: "8px",
            transition: "all 0.25s",
            textAlign: "center",
            "&:hover,&.active": {
              backgroundColor: "#77d0ea",
              color: "#00586a",
              "svg path": {
                stroke: "#00586a",
              },
            },
            "&.disabled": {
              pointerEvents: "none",
            },
          },
        }}
        className="sidebar"
      >
        <ul>
          {/* Static Sidebar Options */}
          {/*<li>
            <NavLink to="/demopage2" className="nav-link disabled" end>
              <SVG src={Home} alt="Home" />
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            {location.pathname.split("/").includes("demopage") ? (
              <NavLink to="/demopage1" className="nav-link disabled" end>
                <SVG src={DashBoardLogo} alt="Dashboard" />
                <span>Dashboard</span>
              </NavLink>
            ) : (
              <NavLink to="/dashboard" className="nav-link" end>
                <SVG src={DashBoardLogo} alt="Dashboard" />
                <span>Dashboard</span>
              </NavLink>
            )}
            </li>*/}
  
          {/* Dynamic Sidebar Options */}
          <li>
              <NavLink to="/dashboard" className="nav-link" end>
                <SVG src={DashBoardLogo} alt="Dashboard" />
                <span>Dashboard</span>
              </NavLink>
          </li>
          {renderedList}
        </ul>
      </Box>
    );
  };
  
  export { SideBar };
