import React, {createContext,useState} from "react";
import { styled, useTheme,  } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  CssBaseline,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MuiDrawer from "@mui/material/Drawer";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import WidgetsIcon from "@mui/icons-material/Widgets";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import Header from "./Header";
import { useFetchUserLogedInData } from "../../hooks/chartHook";

const drawerWidth = 260;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const LeftSideDrawerButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CountBadge = styled("span")(({ theme }) => ({
  minWidth: "2rem",
  padding: ".25rem",
  borderRadius: "5px",
  textAlign: "center",
  color: "#fff",
  fontSize: ".75rem",
  backgroundColor: "#D87474",
}));
export const ColorContext = createContext();

const DashboardPage = () => {
   const [avatar, setAvatar] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);



  const {
    data: userInfo,
    isLoading,
    isFetching,
    error,
  } = useFetchUserLogedInData({
    userId: localStorage.getItem('USERID'),
    enableRefetch:false
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openSubmenu, setOpenSubmenu] = React.useState(true);

  const handleClick = () => {
    setOpenSubmenu(!openSubmenu);
  };

  const DashboardOuter = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {},

    [theme.breakpoints.up("lg")]: {
      //paddingRight: "19rem",
    },
    [theme.breakpoints.down("xl")]: {
     // paddingRight: "15rem",
    },
  }));

  const handleLogOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem("isLogged");
    localStorage.removeItem('USERID');
    localStorage.removeItem('COMPANYNAME');
    // navigate("/login");
    const externalLandingPageUrl = 'https://decisionow.io/';
    window.location.href = externalLandingPageUrl;
  }


  return (
    <ColorContext.Provider value={{ avatar, setAvatar }}>
   
      <Header data={avatar} userInfo={userInfo}></Header>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            top: "4.015rem",
            background: "#FFF",
            border: "none",
            boxShadow: 3,
            zIndex: "9",
          },
        }}
      >
        <DrawerHeader>
          <LeftSideDrawerButton
            position="absolute"
            left="0"
            top="0"
            open={open}
            style={{ background: "#fff" }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
                open={open}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </LeftSideDrawerButton>

          <IconButton
            onClick={handleDrawerClose}
            style={{ position: "absolute", left: ".5rem", top: "1rem" }}
          >
            {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>
        <Box
          height={0.85}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItemButton style={{ width: "100%" }}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <Link to="/dashboard">
                <ListItemText primary="Home" />
              </Link>
              <CountBadge>2</CountBadge>
            </ListItemButton>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <Badge color="secondary" badgeContent=" " variant="dot">
                  <WidgetsIcon />
                </Badge>
              </ListItemIcon>
              <Link to="/dashboard">
                <ListItemText primary="Dashboard" />
              </Link>
              {openSubmenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ ml: 6, pl: 3 }}>
                  <Link to="/dashboard/socialsentiment">
                    <ListItemText primary="Social Sentiments" />
                  </Link>
                </ListItemButton>
                <ListItemButton sx={{ ml: 6, pl: 3 }}>
                  <Link to="#">
                    <ListItemText primary="Social Media" />
                  </Link>
                </ListItemButton>
                <ListItemButton sx={{ ml: 6, pl: 3 }}>
                  <Link to="/dashboard/cohort">
                    <ListItemText primary="Cohort Analysis" />
                  </Link>
                </ListItemButton>
              </List>
            </Collapse>
          </List>

          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Bookmark" />
            </ListItemButton>
            <ListItemButton onClick={handleLogOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt="Drew Cano"
                  src="https://mui.com//static/images/avatar/2.jpg"
                  sx={{ width: 36, height: 36 }}
                ></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Drew Cano"
                secondary="drewcano@gmail.com"
              />
            </ListItem>
          </List>
        </Box>
      </Drawer> 
            <Box width="100%">
        <DrawerHeader />
   
       <Box className="outlet-container">
     
      <Outlet  />
       </Box>
     
        
      </Box>
      
      </ColorContext.Provider>
  );
};

export { DashboardPage };
