// import { LayOutView } from "../LayOutView/LayOutView";
// import { Outlet } from "react-router-dom";
// const DashBoardView = () => {
//   return (
//     <>
//       <LayOutView>
//         <div className="outlet-container">
//           <Outlet />
        
//         </div>
//       </LayOutView>
//     </>
//   );
// };
// export { DashBoardView };
import React from "react";
import { LayOutView } from "../LayOutView/LayOutView";
import { Link } from "react-router-dom";
import "./DashBoardView.css"; // Import your stylesheet for styling

const DashBoardView = () => {
  const pagePreviews = [
    { path: "/marketing", title: "Marketing", preview: "Marketing Data Preview" },
    { path: "/hranalytics", title: "HR Analytics", preview: "HR Analytics Data Preview" },
    { path: "/finance", title: "Finance", preview: "Finance Data Preview" },
    { path: "/sales", title: "Sales", preview: "Sales Data Preview" },
    { path: "/hrworkforce", title: "HR Workforce", preview: "HR Workforce Data Preview" },
    { path: "/customerservice", title: "Customer Service", preview: "Customer Service Data Preview" },
    { path: "/subscription", title: "Subscription", preview: "Subscription Data Preview" },
    { path: "/emailcampaign", title: "Email Campaign", preview: "Email Data Preview" },
    // Add more pages with their paths, titles, and data previews
  ];

  return (
    <LayOutView>
      <div className="dashboard-container">
        <div className="dashboard-gallery">
          {pagePreviews.map((page) => (
            <Link key={page.path} to={page.path} className="gallery-item">
              <div className="dashboard-preview">
                <h3>{page.title}</h3>
                <p>{page.preview}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </LayOutView>
  );
};

export { DashBoardView };
