// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3A3A44;
  text-decoration: none;
  width: 100%;
}

.mainNav nav a {
  padding: 5px 20px;
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;EACA,WAAA;AAAR;;AAMQ;EACI,iBAAA;EACA,WAAA;AAHZ","sourcesContent":["nav {\n    a {\n        font-size: 1rem;\n        font-weight: 400;\n        line-height: 22px;\n        letter-spacing: 0em;\n        text-align: left;\n        color: #3A3A44;\n        text-decoration: none;\n        width: 100%;\n    }\n\n}\n.mainNav {\n    nav {\n        a {\n            padding: 5px 20px;\n            width: auto;\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
