import { LayOutView } from "../LayOutView/LayOutView";
import { useSelector } from "react-redux";
const Finance = () => {
  const CategoryName = "Finance";
  const userDashboardObject = useSelector((state) => state.storeData.userDashboardInfo);
  const getCategoryDashboardUrl = (categoryName) => {
    const category = userDashboardObject?.data.find((item) => item.category_name === categoryName);
    return category ? category.dashboard_url : null;
  };
  const dashboardUrl = getCategoryDashboardUrl(CategoryName);
  return (
    <>
      <LayOutView>
        <div class="responsive-iframe iframe">
          <iframe
  title="Report Section"
  src={dashboardUrl}
  frameBorder="0"
  height="100%"
  width="100%"
  style={{
    position: "absolute",
    clip: "rect(0px, 1440px, 2500px, 0px)",
    bottom: "0px"
  }}
  allowFullScreen={true}
></iframe>

        </div>
      </LayOutView>
    </>
  );
};
export { Finance };
