import { useMutation, useQuery } from "react-query";
import environment from "../config/environment";
const axios = require("axios");

const SAMPLESIZECHARTDATA = "sample-size-chart-data";
const FETCHUSERLOGEDINDATA = "fetch-user-loged-in-data";

const getSampleSizeResponse = () => {
  const getData =
    environment.web.flaskbackendUri +
    "/userinfo/" +
    window.localStorage.getItem("USERID");
  //  const getDataByYear = year === 'All' || year === null
  // ? 'http://3.70.29.145:5000/cxmetrics/' + window.localStorage.getItem('USERID')
  // : 'http://3.70.29.145:5000/cxmetrics/' + window.localStorage.getItem('USERID') + '?year=' + year;
  return axios
    .get(getData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // handle error
    });
};

const useSampleSizeData = ({ enabled = true, enableRefetch }) => {
  return useQuery(
    [SAMPLESIZECHARTDATA],
    async () => {
      const { data } = await getSampleSizeResponse();
      return data;
    },
    {
      enabled,
      retry: 3,
      retryDelay: 3,
      staleTime: 5 * 60 * 1000,
      refetchInterval: enableRefetch ? 1000 : false,
      onSettled: (data, error) => {
        return data;
      },
    }
  );
};

const getLogedInUserResponse = (userId) => {
  const getDataByYear = environment.web.backendUri + `/user/info/${userId}`;
  const accessToken = localStorage.getItem("token");
  return axios
    .get(getDataByYear, {
      headers: {
        ...(accessToken && {
          Authorization: "Bearer " + accessToken,
        }),
      },
    })
    .then((response) => {
      console.log("getLogedInUserResponse>>", response);
      return response;
    })
    .catch((error) => {
      console.log(
        "CHECK ERROR ON LOGGEDIN user if token malfal-->",
        error.response.data.message
      );
      if (
        error.response?.data?.message === "jwt expired" ||
        error.response.data.message === "you must be logged in" ||
        error.response?.data?.message === "jwt malformed" ||
        error.response?.data?.message === "Invalid token"
      ) {
        localStorage.removeItem("token");
      }
    });
};

const getYearlyResponse = (year, month) => {
  const getDataByYear =
    environment.web.flaskbackendUri +
    "/cxmetrics/" +
    window.localStorage.getItem("USERID");
  return axios
    .post(getDataByYear, {
      year: year,
      month: month,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // handle error
      console.error("Error posting data:", error);
    });
};
const useFetchUserLogedInData = ({ enabled = true, userId }) => {
  return useQuery(
    [FETCHUSERLOGEDINDATA, userId],
    async () => {
      const { data } = await getLogedInUserResponse(userId);
      return data;
    },
    {
      enabled,
      retry: 3,
      retryDelay: 3,
      staleTime: 5 * 60 * 1000,
      onSettled: (data, error) => {
        return data;
      },
    }
  );
};

const useFetchAllYearData = ({ year, month }) => {
  return useQuery(
    ["yearData", year, month],
    async () => {
      const { data } = await getYearlyResponse(year, month);
      return data;
    },
    {
      enabled: true,
      retry: 3,
      retryDelay: 3,
      staleTime: 5 * 60 * 1000,
      onSettled: (data, error) => {
        return data;
      },
    }
  );
};

const postYearAndMonthSelection = (object) => {
  //'http://3.70.29.145:5000/cxmetrics/' + window.localStorage.getItem('USERID');
  const getDataByYear =
    environment.web.flaskbackendUri +
    "/cxmetrics/" +
    window.localStorage.getItem("USERID");
  return axios
    .post(getDataByYear, object)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // handle error
      console.error("Error posting data:", error);
    });
};

const useFetchDashboardChartsData = () => {
  const fetchChartData = useMutation(
    async (object) => {
      const { data } = await postYearAndMonthSelection(object);
      return data;
    },
    {
      retry: 3,
      retryDelay: 3,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      onSettled: (response, error) => {
        if (error) {
          console.log("HOOK ERROR-->", error);
        }
        return response;
      },
    }
  );
  return { fetchChartData };
};

export {
  useSampleSizeData,
  useFetchUserLogedInData,
  useFetchDashboardChartsData,
  useFetchAllYearData,
};
