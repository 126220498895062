import { React, useState } from "react";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import styled from "styled-components";
import "./SocialSentimentView.css";
import Walmarticon from "../assets/images/walmart-icon.svg";
import Ellipseplaceolder from "../assets/images/ellipse-placeolder.svg";
import Listerine from "../assets/images/listerine.png";
import aclisLogo from "../assets/images/logo-aclis.svg";
import { Link, useLocation } from "react-router-dom";
// For modal
import Listerinebig from "../assets/images/listerine-big.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { RecentPainPointModel } from "./RecentPainPointModel";

const SwiperStyling = styled.div`
    & { 
      overflow: hidden;
      padding-bottom:2.75rem;
   .swiper-pagination-bullet {
        width: 12px ; 
        height: 12px ;
    }
    .swiper-pagination-bullet-active {
      width: 32px ;
      height: 12px;
      border-radius:31px;
  }
   .testimonials-slides .swiper-pagination-bullet-active {
        width: 16px ;
        height: 16px;
        border-radius:50%;
    }
    .swiper-pagination{
     display: flex;
    justify-content: center;
    align-items: center;
    }
    .swiper-button-prev, .swiper-button-next{
      top:calc(100% + 3rem)
    }
    .testimonials-slides .swiper-button-next {
      color: #000;
      transform: translateY(-35%);
      transition: all 0.25s;
      background-color:  #FDDCDA;
      color: #AD5050;
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 24px;
      left:calc(50% + 10px);
      right:auto;
      @media screen and (max-width: 991px) {
        display:none!important;
        }
    } 
    .testimonials-slides .swiper-button-next:hover {
      background-color: #AD5050;
      color: #ffffff
    }  
    .testimonials-slides .swiper-button-prev {
      color: #000;
      transform: translateY(-35%);
      transition: all 0.25s;
      background-color:  #FDDCDA;
      color: #AD5050;
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 40px;
      right:calc(50% + 10px);
      left:auto;
      @media screen and (max-width: 991px) {
      display:none!important;
      }
    }
    
    .testimonials-slides .swiper-button-prev:hover {
      background-color: #AD5050;
      color: #ffffff
    } 
      }
    }
  `;

const Painpoint = (props) => {
  const { painPointTable } = props?.dataKpi;
  const [fetchSelectedPainPoint, setSelectedPainPoint] = useState();
  const location = useLocation();
  // For modal
  const [open, setOpen] = useState(false);
  const handleClickOpen = (item) => {
    setOpen(true);
    setSelectedPainPoint(item);
  };
  const showDemoPagePainPointModel = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false); // for dempage code
  };
  const handleChildData = () => {
    setOpen(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {" "}
        <h3>Sentiments and Actionable Recommendations</h3>{" "}
        <Box
          sx={{
            a: {
              color: "#00586a",
              fontSize: "0.875rem",
              fontWeight: "500",
              "&:hover": {
                color: "#AD5050",
              },
            },
          }}
          position="relative"
        >
          {/* <Link style={{pointerEvents:"none"}}   to="/dashboard">See All</Link> */}
        </Box>
      </Box>
      <SwiperStyling>
        <Box
          sx={{
            ".card": {
              flex: "1 0",
              padding: "1.5rem",
              borderRadius: "1.5rem",
              display: "flex",
              flexDirection: "column",
              background: "#FFF",
              gap: "0.75rem",
              ".card-header": {
                background: "transparent",
                textAlign: "left",
                color: "#1C1C1C",
                "& h2": {
                  fontSize: "1rem",
                  fontWeight: "400",
                  letterSpacing: "0.04px",
                  margin: "0",
                },
              },
              ".card-body": {
                background: "transparent",
                fontSize: "1rem",
                color: "#1C1C1C",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "1rem",
                "& p": {
                  fontSize: "1.625rem",
                  fontWeight: "700",
                  letterSpacing: "0.065px",
                  margin: "0",
                  paddingRight: "0.5rem",
                },
                ".card-text": {
                  flex: "1 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  ".top-section": {
                    flex: "1 0",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    ".walmart": {
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    },
                    ".end-text": {
                      maxWidth: "40%",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    },
                  },
                  ".pain-text": {
                    fontSize: "1.25rem",
                    fontWeight: "600",
                    letterSpacing: "0.05px",
                    lineHeight: "1.875rem",
                    color: "#3A3A44",
                    maxWidth: "100%",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  },
                },
              },
              ".card-small": {
                padding: "0.75rem 1rem",
                borderRadius: "7px",
                display: "flex",
                background: "#77d0ea",
                gap: "0.75rem",
                div: {
                  flexWrap: "wrap",
                },
                a: {
                  color: "#00586a",
                  fontSize: "0.875rem",
                  "&:hover": {
                    color: "#AD5050",
                  },
                },
                ul: {
                  listStyleType: "disc",
                  paddingLeft: "1.25rem",
                  li: {
                    marginBottom: "0",
                    textAlign: "left",
                    maxWidth: "100%",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  },
                },
              },
            },
          }}
          className="card-container"
        >
          <Swiper
            navigation={true}
            modules={[Navigation]}
            spaceBetween={25}
            slidesPerView={2.2}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 1.1,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              1200: {
                slidesPerView: 3.0,
              },
              1400: {
                slidesPerView: 3.0,
              },
              1920: {
                slidesPerView: 3.0,
              },
            }}
            style={{
              "--swiper-pagination-color": "#AD5050",
              "--swiper-pagination-bullet-inactive-color": "#D9D9D9",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
              "--swiper-navigation-color": "#AD5050",
            }}
            className="mySwiper testimonials-slides "
          >
            {location.pathname.split("/").includes("demopage") ? (
              <>
                <SwiperSlide>
                  <Box className="card">
                    <div className="card-body">
                      <Box className="card-text">
                        <Box className="top-section">
                          <Box className="walmart">
                            {" "}
                            <img src={Walmarticon} alt="Walmart" />{" "}
                            <Box display="flex" alignItems="center" gap={1}>
                              <img src={Ellipseplaceolder} alt="Walmart" /> High
                            </Box>
                          </Box>{" "}
                          <Box className="end-text" color="#000000">
                            Food and Drug
                          </Box>{" "}
                        </Box>
                        <Box className="pain-text">
                          Intense flavor that leaves medicine taste in mouth
                        </Box>
                        <Box className="card-small">
                          <Box>
                            {" "}
                            <img src={aclisLogo} alt="Walmart" width={27} />
                          </Box>
                          <Box display="flex" flexDirection="column" gap={1}>
                            <Box display="flex" alignItems="center" gap={1}>
                              <strong>4 Recommendations</strong>
                              <Link onClick={showDemoPagePainPointModel}>
                                View details
                              </Link>
                            </Box>
                            <Box>
                              <ul>
                                <li>From the company's perspective, we ...</li>
                              </ul>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <img src={Listerine} alt="Listerine" width={188} />
                    </div>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box className="card">
                    <div className="card-body">
                      <Box className="card-text">
                        <Box className="top-section">
                          <Box className="walmart">
                            {" "}
                            <img src={Walmarticon} alt="Walmart" />{" "}
                            <Box display="flex" alignItems="center" gap={1}>
                              <img src={Ellipseplaceolder} alt="Walmart" /> High
                            </Box>
                          </Box>{" "}
                          <Box className="end-text" color="#000000">
                            Consumer Protection
                          </Box>{" "}
                        </Box>
                        <Box className="pain-text">
                          Alcohol-free products with bad taste
                        </Box>
                        <Box className="card-small">
                          <Box>
                            {" "}
                            <img src={aclisLogo} alt="Walmart" width={27} />
                          </Box>
                          <Box display="flex" flexDirection="column" gap={1}>
                            <Box display="flex" alignItems="center" gap={1}>
                              <strong>4 Recommendations</strong>
                              <Link onClick={showDemoPagePainPointModel}>
                                View details
                              </Link>
                            </Box>
                            <Box>
                              <ul>
                                <li>Kenvue understands the importance of..</li>
                              </ul>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <img src={Listerine} alt="Listerine" width={188} />
                    </div>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box className="card">
                    <div className="card-body">
                      <Box className="card-text">
                        <Box className="top-section">
                          <Box className="walmart">
                            {" "}
                            <img src={Walmarticon} alt="Walmart" />{" "}
                            <Box display="flex" alignItems="center" gap={1}>
                              <img src={Ellipseplaceolder} alt="Walmart" /> High
                            </Box>
                          </Box>{" "}
                          <Box className="end-text" color="#000000">
                            Legal Department
                          </Box>{" "}
                        </Box>
                        <Box className="pain-text">
                          Mouthwash burning sensation due to intense mintiness
                        </Box>
                        <Box className="card-small">
                          <Box>
                            {" "}
                            <img src={aclisLogo} alt="Walmart" width={27} />
                          </Box>
                          <Box display="flex" flexDirection="column" gap={1}>
                            <Box display="flex" alignItems="center" gap={1}>
                              <strong>4 Recommendations</strong>
                              <Link onClick={showDemoPagePainPointModel}>
                                View details
                              </Link>
                            </Box>
                            <Box>
                              {" "}
                              <ul>
                                <li>The solution from the company's pers...</li>
                              </ul>{" "}
                            </Box>
                          </Box>
                        </Box>
                      </Box>{" "}
                      <img src={Listerine} alt="Listerine" width={188} />
                    </div>
                  </Box>
                </SwiperSlide>
              </>
            ) : (
              <>
                {painPointTable.slice(0, 25).map((item) => (
                  <SwiperSlide>
                    <Box className="card">
                      <div className="card-body">
                        <Box className="card-text">
                          <Box className="top-section">
                            <Box className="walmart">
                              {item.source}
                              <Box display="flex" alignItems="center" gap={1}>
                                <img src={Ellipseplaceolder} alt="Walmart" />{" "}
                                {item.severityIndex}
                              </Box>
                            </Box>
                            <Box className="end-text" color="#000000">
                              {item.area}
                            </Box>
                          </Box>
                          <Box className="pain-text">{item.painPoint}</Box>
                          <Box className="card-small">
                            <Box>
                              {" "}
                              <img src={aclisLogo} alt="Walmart" width={27} />
                            </Box>
                            <Box display="flex" flexDirection="column" gap={1}>
                              <Box display="flex" alignItems="center" gap={1}>
                                <strong>Recommendations</strong>
                                <Link onClick={() => handleClickOpen(item)}>
                                  View details
                                </Link>
                              </Box>
                              <Box>
                                {" "}
                                <ul>
                                  <li>{item.resolution}</li>
                                </ul>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </Box>
      </SwiperStyling>
      {location.pathname.split("/").includes("demopage") ? (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            View Detail
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#3A3A44",
              svg: {
                color: "#3A3A44",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box
              sx={{
                ".card": {
                  padding: "0rem",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  background: "#FFF",
                  gap: "0.75rem",
                  ".top-section": {
                    flex: "1 0",
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "space-between",
                    ".walmart": {
                      display: "flex",
                      gap: "1rem",
                      alignItems: "flex-start",
                    },
                    ".end-text": {
                      maxWidth: "60%",
                    },
                  },
                  ".card-header": {
                    background: "transparent",
                    textAlign: "left",
                    color: "#1C1C1C",
                    "& h2": {
                      fontSize: "1rem",
                      fontWeight: "400",
                      letterSpacing: "0.04px",
                      margin: "0",
                    },
                  },
                  ".card-body": {
                    background: "transparent",
                    fontSize: "1rem",
                    color: "#1C1C1C",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    padding: "0rem",
                    gap: "1rem",
                    "& p": {
                      fontWeight: "500",
                      fontSize: "0.875rem",
                    },
                    ".card-text": {
                      flex: "1 0",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",

                      ".pain-text": {
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        letterSpacing: "0.05px",
                        lineHeight: "1.875rem",
                        color: "#3A3A44",
                        paddingBottom: "1rem",
                        borderBottom: "1px dashed #E1E1E3",
                      },
                    },
                  },
                  ".card-small": {
                    display: "flex",
                    gap: "0.75rem",
                    div: {
                      flexWrap: "wrap",
                    },
                    a: {
                      color: "#00586a",
                      fontSize: "0.875rem",
                      "&:hover": {
                        color: "#AD5050",
                      },
                    },
                    ul: {
                      listStyleType: "disc",
                      paddingLeft: "2rem",
                      fontSize: "0.875rem",
                      li: {
                        marginBottom: "0.5rem",
                        textAlign: "left",
                        lineHeight: "150%",
                      },
                    },
                  },
                },
              }}
            >
              <Box className="card">
                <Box className="top-section">
                  <Box className="walmart">
                    {" "}
                    <img src={Walmarticon} alt="Walmart" />{" "}
                    <Box display="flex" alignItems="center" gap={1}>
                      <img src={Ellipseplaceolder} alt="Walmart" /> High
                    </Box>
                  </Box>{" "}
                  <Box className="end-text" color="#000000">
                    Customer Service
                  </Box>{" "}
                </Box>
                <div className="card-body">
                  <Box className="card-text">
                    <Box className="pain-text">
                      Hard to find good product without artificial sweeteners
                    </Box>
                    <Box className="card-small">
                      <Box display="flex" marginBottom={3} gap={1}>
                        <Box display="flex" alignItems="center" gap={0.5}>
                          <img src={aclisLogo} alt="Walmart" width={27} />
                          <Box>
                            <strong>Aclis recommends</strong>{" "}
                          </Box>
                        </Box>

                        <Box>
                          {" "}
                          <p>
                            From Kenvue's perspective, the solution to this
                            customer complaint is to:
                          </p>
                          <ul>
                            <li>
                              Offer a wide variety of products that are{" "}
                              <strong>free from artificial sweeteners</strong>.
                              This could include natural sweeteners such as
                              honey or stevia, and low-sugar alternatives like
                              fruit juices or smoothies.
                            </li>
                            <li>
                              It would be beneficial for Kenvue to{" "}
                              <strong>clearly label all their products</strong>{" "}
                              with <strong>detailed ingredient lists</strong>
                              so customers can easily identify which items do
                              not contain any artificial sweeteners{" "}
                            </li>
                            <li>
                              <strong>Offer more high-end options</strong> that
                              cater specifically to those looking for{" "}
                              <strong>healthier product choices</strong> without
                              sacrificing quality or flavor.{" "}
                            </li>
                          </ul>{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>{" "}
                  <img src={Listerinebig} alt="Listerine" width={254} />
                </div>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <RecentPainPointModel
          open={open}
          sendDataToParent={handleChildData}
          fetchSelectedPainPoint={fetchSelectedPainPoint}
        ></RecentPainPointModel>
      )}
    </>
  );
};
export { Painpoint };
