import { Box, Typography, Container, Grid } from "@mui/material";
import React from "react";
import customerAnalytics from '../../components/assets/images/customerAnalytics.png';
import improveCustomer from '../../components/assets/images/improveCustomer.svg';
import satisfactionLoyalty from '../../components/assets/images/satisfactionLoyalty.svg';
import helpBusiness from '../../components/assets/images/helpBusiness.svg';
import customerPreferences from '../../components/assets/images/customerPreferences.svg';
import hubspot from '../../components/assets/images/hubspot.png';
import freshdesk from '../../components/assets/images/freshdesk.png';
import hotjar from '../../components/assets/images/hotjar.png';
import splunk from '../../components/assets/images/splunk.png';
import slack from '../../components/assets/images/slack.png';
import asana from '../../components/assets/images/asana.png';
import datalake from '../../components/assets/images/datalake.png';

const MaximisingProductPotential = () => {
  return (
    <>
      <Box sx={{ background: "radial-gradient(30.69% 94.13% at 32.88% 49.99%, #77d0ea 0%, rgba(254, 237, 236, 0.546265) 45.37%, rgba(254, 237, 236, 0) 100%)", width: "100%" }} pt={7} pb={7}>
        <Container maxWidth="xl">
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "flex-start", }}>
            <Box px={2} sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }} pt={1}>
              <Box pb={3}>
                <Typography variant="h2" fontWeight={500} textAlign="left" color="#292A31">
                  Why there’s need of Customer Analytics
                </Typography>
                <Typography variant="body1" fontWeight={400} textAlign="left" pt={5}>
                  Unlocking Customer Insights for Better Business Outcomes: The Importance of Customer Insights Tools
                </Typography></Box>
              <Box display="flex" mt={4}>
                <Box display="flex" mr={3} width={1/2}>
                  <Box mr={2}>
                    <img width="40" src={improveCustomer} alt={improveCustomer} />
                  </Box>
                  <Typography variant="body1" fontWeight={300}>Improve customer experience</Typography>
                </Box>
                <Box display="flex" width={1/2}>
                  <Box mr={2}>
                    <img width="40" src={satisfactionLoyalty} alt={satisfactionLoyalty} />
                  </Box>
                  <Typography variant="body1" fontWeight={300}>Drive customer satisfaction, loyalty, and revenue
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" mt={6}>
                <Box display="flex" mr={3} width={1/2}>
                  <Box mr={2}>
                    <img width="40" src={helpBusiness} alt={helpBusiness} />
                  </Box>
                  <Typography variant="body1" fontWeight={300}>Help businesses build better relationships with their customers
                  </Typography>
                </Box>
                <Box display="flex" width={1/2}>
                  <Box mr={2}>
                    <img width="40" src={customerPreferences} alt={customerPreferences} />
                  </Box>
                  <Typography variant="body1" fontWeight={300}>Provide data on customers' preferences, behaviours, and motivations
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "40%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
              <Box alignContent="left" >
                <img src={customerAnalytics} alt={customerAnalytics} width="100%"  />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MaximisingProductPotential;
