import "./App.css";
import { Routes, Route, useLocation,} from "react-router-dom";
import { Header, Footer } from "./components";
import { DashBoardView } from "./component/DashBoardView/DashBoardView";
import { Marketing } from "./component/Marketing/Marketing";
import { HRAnalytics } from "./component/HRAnalytics/HRAnalytics";
import { Finance } from "./component/Finance/Finance";
import { Sales } from "./component/Sales/Sales";
import { HRWorkforce } from "./component/HRWorkforce/HRWorkforce";
import { Subscription } from "./component/Subscription/Subscription";
import { EmailCampaign } from "./component/EmailCampaign/EmailCampaign";
import { CustomerService } from "./component/CustomerService/CustomerService";
import {
  ProfilePage,
  LoginPage,
  ContactUsPage,
  RegistrationPage,
  DemoPage,
  LandingPage,
  DashboardPage,
  SocialSentiment,
  MutipleDashboardLayout,
  PricingPage,
  DemoSocialSentiment,
  SplashScreen,
  // CohortAnalysis,
} from "./pages";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  const location = useLocation();

  const redirectToExternalLandingPage = () => {
    
    const externalLandingPageUrl = 'https://decisionow.io/';
    window.location.href = externalLandingPageUrl;
  };
  const divStyle = {
    position: 'absolute',
    width: '100%',
    zIndex: 9999,
  };
  return (
    <>
      {location.pathname.split("/").includes("dashboard") ||
      location.pathname.split("/").includes("home") ||
      location.pathname.split("/").includes("competitiveanalysis") ||
      location.pathname.split("/").includes("cohortanalysis") ||
      location.pathname.split("/").includes("demopage") ? (
        ""
      ) : (
        <Header />
      )}

      <Routes>
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/splashscreen" element={<SplashScreen />}></Route>
        <Route exact path="/contactus" element={<ContactUsPage />}></Route>
        <Route
          exact
          path="/registration"
          element={<RegistrationPage />}
        ></Route>
        <Route exact path="/pricing" element={<PricingPage />}></Route>
        <Route exact path="/demo" element={<DemoPage />}></Route>
        {/* <Route exact path="/landing" element={<LandingPage />} /> */}
        <Route exact path="/landing" element={redirectToExternalLandingPage} />
        {/* new design app routing  */}
        {/* <Route
          exact
          path="/home"
          element={
            <PrivateRoute>
              <HomeView />
            </PrivateRoute>
          }
        /> */}
        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashBoardView />
            </PrivateRoute>
          }
        >
          {/* <Route
            path="seeAllSocialSentiment"
            element={<SeeAllSocialSentiment />}
          />
          <Route index element={<SocialSentimentView />} /> */}
        </Route>

      

        <Route
          exact
          path="/sales"
          element={
            <PrivateRoute>
              <Sales></Sales>
            </PrivateRoute>
          }
        />
           <Route
          exact
          path="/finance"
          element={
            <PrivateRoute>
              <Finance></Finance>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/hranalytics"
          element={
            <PrivateRoute>
              <HRAnalytics></HRAnalytics>
            </PrivateRoute>
          }
        />
          <Route
          exact
          path="/hrworkforce"
          element={
            <PrivateRoute>
              <HRWorkforce></HRWorkforce>
            </PrivateRoute>
          }
        />
           <Route
          exact
          path="/customerservice"
          element={
            <PrivateRoute>
              <CustomerService></CustomerService>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/emailcampaign"
          element={
            <PrivateRoute>
              <EmailCampaign></EmailCampaign>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/marketing"
          element={
            <PrivateRoute>
              <Marketing />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/subscription"
          element={
            <PrivateRoute>
              <Subscription></Subscription>
            </PrivateRoute>
          }
        />

        {/* <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        >
          <Route path="cohort" element={<CohortAnalysis />} />
          <Route path="socialsentiment" element={<SocialSentiment />} />
          <Route path="profilepage" element={<ProfilePage />} />
          <Route index element={<MutipleDashboardLayout />} />
        </Route> */}
        <Route path="/demopage" element={<DemoSocialSentiment />} />
        <Route path="*" element={redirectToExternalLandingPage} />
      </Routes>
      <div className="App"></div>
      {location.pathname.split("/").includes("demopage") ||
      location.pathname.split("/").includes("dashboard") ||
      location.pathname.split("/").includes("home") ||
      location.pathname.split("/").includes("cohortanalysis") ||
      location.pathname.split("/").includes("competitiveanalysis") ? (
        ""
      ) : (
        <div style={divStyle}>
          {/* <Footer /> */}
        </div>
        
      )}
    </>
  );
}

export default App;
